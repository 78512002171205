import * as React from 'react'
import Layout from '../components/Layout'
import Gallery from '../components/Gallery'

const IndexPage = () => 
  <Layout>
    <Gallery />
  </Layout>

export default IndexPage
